<template>
  <div class="sidebar" :style="getHeight()">  
    <ul v-if="show_error" class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
        data-accordion="false">
        <li class="nav-item a-nav-filter-item">
            <div class="custom-control custom-checkbox">
                <input :checked="show_error" id="show_error" @click="changeShowError" class="custom-control-input" type="checkbox" name="show_error" />
                <label for="show_error" class="custom-control-label">Show error(s) only</label>
            </div>
        </li>
    </ul>
    <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
            data-accordion="false">
            <!-- <template v-if="[2, 7].includes(access_level()) && this.$route.name != 'search'"> -->
            <template v-if="this.$route.name != 'search'">
                <li
                    class="nav-item a-nav-filter-item v-filter-my-qa-data"
                    v-restrict="'v-filter-my-qa-data'"
                >
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" name="data-shown" id="my-qa-data" v-model="shown_data" value="QD">
                        <label for="my-qa-data" class="custom-control-label">{{ access_level() == 2 ? 'My Data' : 'My QA Data' }}</label>
                    </div>
                </li>
            </template>
            <!-- <template v-if="[5, 7].includes(access_level()) && this.$route.name != 'search'"> -->
            <template v-if="this.$route.name != 'search'">
                <li
                    class="nav-item a-nav-filter-item v-filter-my-research-data"
                    v-restrict="'v-filter-my-research-data'"
                >
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" name="data-shown" id="my-research-data" v-model="shown_data" value="RD">
                        <label for="my-research-data" class="custom-control-label">My Research Data</label>
                    </div>
                </li>
            </template>
        </ul>
    </nav>
    
    <h4 
        id="filter-research-status"
        :style="`cursor:pointer; ${ [7,2].includes(access_level()) ? 'border-top: 1px solid #eee' : '' }`"
        :class="`a-sidebar-filter-header d-flex justify-content-between align-items-center pr-4 pl-4 ${ [7,2].includes(access_level()) ? 'pt-3 pb-0' : 'pt-2' }`"
        data-toggle="collapse"
        data-target="#collapseStatus"
        title="Click to show/hide research statuses">
        <div>
            <img class="a-sidebar-filter-header-icon mr-2" src="/static/svg/Research Status.svg" alt="icon">
            Research Status 
        </div>
        <i class="d-block text-muted fas fa-ellipsis-h"></i>
    </h4>

    <nav class="mt-2 collapse show" id="collapseStatus" aria-labelledby="filter-research-status">
        <!-- <div class="pl-4 pt-2 pb-3" style="border-bottom: 1px solid rgb(238, 238, 238);">
            <div class="custom-control custom-checkbox">
                <input class="custom-control-input" type="checkbox" name="show-status" id="show-substatus-checkbox" :value="true" v-model="subStatusesStore.showListingsWithSubstatuses" />
                <label for="show-substatus-checkbox" class="custom-control-label">Show Listings With Sub-status set</label>
            </div>
        </div> -->

        <ArLoaderinator :loading="!dem_statuses.length">
            <div style="min-height: 100px">
                <div id="status-accordion" class="btn-group-vertical btn-group-toggle w-100 pt-2 pl-3 pr-3 pb-3" data-toggle="buttons">
                    <div v-for="(status, index) in dem_statuses" :key="index" class="w-100">
                        <div class="position-relative w-100">
                            <label
                                class="btn btn-research-status text-capitalize w-100 mb-0" 
                                @click="selected_dem_status = index, changeRadio(status.label,selected_dem_status)" 
                                :class="[{'active': urldecode($route.query.status) == status.label}, getFeatureName(status)]"
                                v-restrict="getFeatureName(status)"
                                :id="`research-status-${ status.label.replace(/\s/g, '-')}`.toLowerCase()"
                            >
                                <input 
                                    type="radio" 
                                    name="statuses" 
                                    :value="status.label" 
                                    :id="`status-${status.label != null ? status.label.replace(/\s/g, '-') : ''}`" 
                                    :checked="urldecode($route.query.status) == status.label"
                                    v-show="false"
                                > 
                                    <span 
                                        class="btn-research-status-icon text-uppercase mr-2" 
                                        :class="[`color-${randomColorNumber()}`]"
                                    >
                                        {{status.label == "Recheck" ? "F" : status.label == 'Hacked' ? 'A' : status.label[0] }}
                                    </span> 
                                        {{status.label == "Recheck" ? "For Rework" : status.label == 'Hacked' ? 'Auto Qualified' : status.label }} {{ !$route.path.includes('verification/search') ? status.val.count !== "" ? `[${status.val.count}]` : '' : '' }}
                            </label>

                            <a :data-toggle="status.val?.sub_statuses?.length ? 'collapse' : ''"
                                :data-target="status.val?.sub_statuses?.length ? `#ver-filter-${(status.label).toLowerCase().replace(/\s/g, '-')}` : ''"
                                aria-expanded="true" :aria-controls="`ver-filter-${(status.label).toLowerCase().replace(/\s/g, '-')}`"
                                role="button"
                                v-restrict="getFeatureName(status)">
                                <i v-if="status.val?.sub_statuses?.length" 
                                    class="d-block text-muted fas fa-caret-down float-right" 
                                    style="font-size: 20px; margin-top: 2px; padding: 8px 20px 8px 10px;" v-tooltip="`Show ${status.label} sub-statuses`"></i>
                            </a>
                        </div>
                        <div 
                            :id="`ver-filter-${(status.label).toLowerCase().replace(/\s/g, '-')}`" 
                            :aria-labelledby="`ver-filter-${(status.label).toLowerCase().replace(/\s/g, '-')}`"
                            data-parent="#status-accordion"
                            class="collapse w-100 ver-filter-cases" 
                            :class="[{'show': urldecode($route.query.status) == status.label }]"
                            v-restrict="getFeatureName(status)"
                        >
                            <div class="btn-group-vertical btn-group-toggle w-100 pt-1 pl-3 pr-0 pb-3" v-if="subStatusesStore.canSeeSubStatuses && status.val?.sub_statuses?.length">
                                <!-- v-for="(tag, tagIndex) in case_status_options" -->
                                <label
                                    v-for="(tag, tagIndex) in status.val.sub_statuses"
                                    class="btn btn-research-status sub-status"
                                    style="padding-left: 30px;"
                                    @click="updateSubStatus(status.label, tag.id ?? `${0}_${status.label?.replaceAll(' ', '_')}`)"
                                    :class="[{'active': $route.query.substatus_id != null && $route.query.substatus_id == (tag.id ?? `${0}_${status.label?.replaceAll(' ', '_')}`) }]"
                                    :key="index + tagIndex"
                                >
                                    <input v-show="false" type="radio" :name="`${status.label}--sub_statuses`" :value="tag.id" :id="`${status.label}--sub-status-${tag.id ?? (0 + ' ' + status.label?.replaceAll(' ', '_'))}`" :checked="$route.query.substatus_id == tag.id">{{tag.label}} {{ tag.count !== "" && typeof tag.count != "undefined" ? `[${tag.count}]` : '' }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ArLoaderinator>
    </nav>

    <h4 
        id="filter-platform"
        style="cursor:pointer; border-top: 1px solid #eee"
        class="a-sidebar-filter-header d-flex justify-content-between align-items-center pt-3 pr-4 pl-4 pb-1"
        data-toggle="collapse"
        data-target="#collapsePlatform"
        title="Click to show/hide platforms">
        <div>
            <img class="a-sidebar-filter-header-icon mr-2" src="/static/svg/Platforms.svg" alt="icon">
            Platforms
        </div>
        <i class="d-block text-muted fas fa-ellipsis-h"></i>
    </h4>

    <nav class="mt-2 collapse show" id="collapsePlatform" aria-labelledby="filter-research-platform">
        <div style="min-height: 100px">
            <div class="d-flex align-items-center justify-content-between">
                <ar-textfield
                    id="filter-platforms-ver"
                    icon="fas fa-search"
                    class="ml-3 mb-0"
                    a_style="width: 193px;"
                    placeholder="Filter platforms"
                    v-model="filter_platform"
                    :disabled="[1].includes(access_level()) ? Listing_has_clientListings : false"
                />
                <div>
                    <ar-button
                        @click="applyPlatformFilters"
                        class="d-inline-block mr-2"
                        style="font-size: 12px; font-weight: 300"
                        title="Apply specified platforms filter on search"
                        :disabled="[1].includes(access_level()) ? Listing_has_clientListings : false"
                    >
                        Apply
                    </ar-button>
                </div>
            </div>
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                data-accordion="false">
                <li v-if="$route.name == 'search' && platforms_loading">
                    <div class="text-center p-4">
                        <div v-for="(spin,index) in 3" :key="index" class="spinner-grow text-dark" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span class="d-block mt-3">Getting Platforms..</span>
                    </div>
                </li>
                <template v-else>
                    <li class="nav-item a-nav-filter-item">
                        <div class="custom-control custom-checkbox">
                            <input :disabled="[1].includes(access_level()) ? Listing_has_clientListings : false" value="0" class="custom-control-input" type="checkbox" id="All Platforms" name="platforms"  @change="changeCheckbox({name: 'All', value: 0})" :checked="selected_checkbox_count == 0" @click="uncheckPlatforms">
                            <label for="All Platforms" class="custom-control-label">All Platforms</label>
                        </div>
                    </li>
                    <li class="nav-item a-nav-filter-item" v-for="(platform,index) in dem_platforms" :key="index">
                        <div class="custom-control custom-checkbox">
                            <input :disabled="[1].includes(access_level()) ? Listing_has_clientListings : false" class="custom-control-input" type="checkbox" name="platforms" :id="platform.name" :value="platform.value" @change="changeCheckbox(platform)">
                            <label :for="platform.name" class="custom-control-label">{{platform.name}}</label>
                        </div>
                    </li>
                </template>
            </ul>
        </div>
    </nav>
  </div>
</template>

<script>
import axios from '@/axiosMime'
import {mapActions, mapState} from 'vuex'
import { useSubStatusesStore } from '../../../store/pinia/substatuses';
import { _qflags } from '@/utils/common'
export default {
  name: 'ver-drawer',
  components: {
    ArButton: _ => import('@/components/layouts/Button'),
    ArTextfield: _ => import('@/components/layouts/Textfield'),
    ArLoaderinator: _ => import('@/components/layouts/loading/Loaderinator'),
  },
  async mounted() {
    await sleep(1000)
    if(this.$route.query?.status?.toString().toLowerCase() == 'recheck' || this.$route.query?.status?.toString().toLowerCase() == 'reworked') {
        /*if([6].includes(this.access_level())){
            await sleep(10)
            this.$router.push({name: this.listing_page, query: {status: 'Work in Progress', platform: 0}})
            this.selected_dem_status = 1
        } else {
            await sleep(10)
            this.$router.push({name: this.listing_page, query: {status: 'Client Listing', platform: 0}})
            this.selected_dem_status = 0
        }*/

        const isClient = [6].includes(this.access_level())
        
        await sleep(10)
        this.$router.push({name: this.listing_page, query: {status: isClient ? 'Work in Progress' : 'Client Listing', platform: 0, substatus_id: 0}})
        this.selected_dem_status = +isClient
    }
    await sleep(1000)
    if(this.$route.query.status && this.$route.query.status.toLowerCase() !== 'client listing') {
        if([1].includes(this.access_level()) && this.Listing_has_clientListings) {
            await sleep(10)
            this.$router.push({name: this.listing_page, query: {status: 'Client Listing', platform: 0, substatus_id: 0}})
            this.selected_dem_status = 0
        }
    }

    this.initializeStatuses()
    if (((JSON.parse(localStorage.getItem('ver')) || {}).token || null) != null){
        this.getPlatforms()
    }
  },
  data() {
    return {
      dem_statuses: [],
      dem_platforms: [],
      filter_platform: "",
      joined_platforms: "",
      selected_checkbox_count: 0,
      selected_checkbox: [],
      shown_data: [],
      filters: {
          platforms: []
      },
      platforms_loading: false,
      client_listings_wip: 0,
      client_listings_qa: 0,
      selected_dem_status: 0,
    }
  },
  computed: {
    ...mapState(['show_error', 'feature_access', 'Listing_has_clientListings','client_Listings','hasTriggeredSomething']),
    subStatusesStore() {
        return useSubStatusesStore()
    },
    listing_page() {
        return this.$route.path.includes('search') ? 'search' : 'listing'
    },
    parent() {
        let p = this.$parent
        let count = 0

        while (p.$vnode.componentOptions.tag != 'ar-master' && count < 6) {
            p = p['$parent']
            count++
        }

        return p
    },
    user_id() {
        return JSON.parse(localStorage.getItem('ver'))?.id
    },
    isWeeklyQuotaTrackerRestricted() {
        return this.$featureAccess.isRestricted('v-enable-weekly-quota-tracker')
    },
    showWeeklyQuotaTracker() {
        return !this.isWeeklyQuotaTrackerRestricted && ['listing', 'search'].includes(this.$route.name)
    },
  },
  methods: {
    ...mapActions(['setFilters', 'updateShown', 'changeShowError', 'checkFeatureAccess', 'cancelRequests', 'refreshCancelToken']),
    access_level() {
        const includes= gui => location.pathname.includes(gui)
        const access = includes('verification') 
            ? 'ver'
            : includes('lawfirm')
                ? 'lawfirm'
                : 'admin';

        return JSON.parse(localStorage.getItem(access))?.access_level
    },
    updateSubStatus(status, value) {
        this.$router.push({name: this.$route.name, params: { ...this.$route.params }, query: { ...this.$route.query, status, substatus_id: value }})
    },
    async changeRadio(status, selected_dem_status = -1) {

        /*let has_client_listings = false

        if((this.client_listings_wip > 0 && this.access_level() == 1) || (this.client_listings_qa > 0 && this.access_level() == 2)) {
            has_client_listings = true
            this.$router.push({name: this.listing_page, query: {status: 'Client Listing', platform: this.$route.query.platform}})
        } else{
            has_client_listings = false
            this.$router.push({name: this.listing_page, query: {status: status, platform: this.$route.query.platform}})
        }*/

        const has_client_listings = (this.client_listings_wip > 0 && this.access_level() == 1) || (this.client_listings_qa > 0 && this.access_level() == 2)
        this.$router.push({name: this.listing_page, query: {status: has_client_listings ? 'Client Listing' : status, platform: this.$route.query.platform, substatus_id: -1}})

        if (has_client_listings) {
            if(selected_dem_status == 0) {
                return
            }
            this.parent.showSnackbar('Cannot proceed to another Status. Please Finish all client listings first.', false, 'invalid','fas fa-times-circle');
        }

        // Added trigger for checking if there are Clients from Listings 

        let trigger_payload = {
            label: 'isTriggerOn_changeStatusFilters',
            value: true,
        }
        await this.$store.dispatch('setTrigger', trigger_payload)
    },
    changeCheckbox(platform) {
        let checked = document.querySelectorAll('input[name=platforms]:checked')
        var platforms = []
        this.filters.platforms = []

        this.selected_checkbox_count = 0

        for (var i = 0; i < checked.length; i++) {
            if (checked[i].value == '0') continue
            this.selected_checkbox_count++
            this.selected_checkbox.push({ id: checked[i].value, name: checked[i].innerHTML})
            platforms.push(checked[i].value)
            this.filters.platforms.push(checked[i].value)
        }

        this.joined_platforms = platforms.join() || '0'
    },
    checkPlatforms() {
        let platforms = document.querySelectorAll('input[name=platforms]')
        this.selected_checkbox_count = 0

        for (var i = 0; i < platforms.length; i++) {
            if (this.joined_platforms != '0' && this.joined_platforms != '') {
                platforms[i].checked = this.joined_platforms.split(',').includes(platforms[i].value.toString())
            } else {
                platforms[i].checked = true
                this.joined_platforms = '0'
                break
            }
        }
    },
    uncheckPlatforms() {
        let platforms = document.querySelectorAll('input[name=platforms]')
        this.selected_checkbox_count = 0

        for (var i = 0; i < platforms.length; i++) {
            if (platforms[i].value == 0) {
                platforms[i].checked = true
                continue
            }

            platforms[i].checked = false
        }

        if (this.$route.name == "listing" || this.$route.name == "search") {
            // this.$router.push({name: this.listing_page, query: {status: this.$route.query.status, platform: 0 }})
            // para mo-effect nalang inig click sa apply. gomenasai :)
            this.joined_platforms = '0'
        }
    },
    applyPlatformFilters() {
      if (this.$route.name == "listing" || this.$route.name == "search") {
          /*if (this.$route.query.keywords != null)
              this.$router.push({name: this.listing_page, params: {campaign_id: this.$route.params.campaign_id}, query: {status: this.$route.query.status, platform: this.joined_platforms, keywords: this.$route.query.keywords }})
          else
              this.$router.push({name: this.listing_page, params: {campaign_id: this.$route.params.campaign_id}, query: {status: this.$route.query.status, platform: this.joined_platforms }})*/

        const dest = { name: this.listing_page, params: { ...this.$route.params }, query: {...this.$route.query, platform: this.joined_platforms }}

        if (this.$route.query.keywords != null)
            dest.query.keywords = this.$route.query.keywords

        this.$router.push(dest)
      }
    },
    getPlatforms() {
      let headers = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)

      headers.headers.Sort = "priority,DESC,name,ASC"
      
      if (this.$route.params.campaign_id != null) {
          headers.headers["Qflag-Id"] = this.parent.getQFlagId(this.$route.query.status) ?? null
          headers.headers.Where = JSON.stringify({status: 1})
          
          if (this.subStatusesStore.canSeeSubStatuses && this.$route.query?.substatus_id != -1)
            headers.headers["Substatus-id"] = this.$route.query?.substatus_id?.toString().includes('0_') ? -1 : this.$route.query?.substatus_id

          axios.get(`${process.env.VUE_APP_URL}/platforms/in/campaign/${this.$route.params.campaign_id}?page_size=1000`, headers)
              .then(response => {
                  let results = response.data
                  this.dem_platforms = results.data.filter(platform => platform.name != 'Others').map(platform=>{ return {value:platform.id,name:platform.name} })
              }
          )
      } else if (this.$route.name == 'search') {
        setTimeout(() => {
            let search_page = this.parent.$children.find(c => c.$vnode.tag.includes('ar-search'))
            let endpoint = "/platforms?page_size=1000"
            let hasFilter = false
            let condition = { status: 1}
    
            if (search_page.filters_set) {
                for (const [key, value] of Object.entries(JSON.parse(search_page.filters_set))) {
                    if (!['my_researcher_data', 'my_qa_data'].includes(key) && value) {
                        hasFilter = true
                    }
                }    
            }
    
            if (hasFilter && (search_page.selected_campaign || search_page.rids.length)) {
                this.platforms_loading = true
                headers.headers["Gui-page"] = "search"
    
                if (search_page != null && typeof search_page != "undefined" && search_page.filters_set) {
                    condition = {...JSON.parse(search_page.filters_set), ...condition}
                }
    
                endpoint = `/search/page/platforms`
    
                if (this.$route.query.status != 'All') headers.headers["Qflag-Id"] = this.parent.getQFlagId(this.$route.query.status)
            }
    
            headers.headers["Where"] = JSON.stringify(condition)
    
            axios.get(`${process.env.VUE_APP_URL}${endpoint}`, headers)
                .then(response => {
                    let results = response.data
                    this.dem_platforms = results.data.map(platform=> { return {value:platform.id,name:platform.name} })
                    
                    setTimeout(() => {
                        this.checkPlatforms()
                    });
                }
            ).finally(() => {
                this.platforms_loading = false
            })
        });
      }
    },
    filterPlatforms() {
      let platforms = document.querySelectorAll('input[name=platforms]')
      let keyword = this.filter_platform == "" ? "" : this.filter_platform.toLowerCase().trim()
      
      for (var index = 0; index < platforms.length; index++) {
          if (platforms[index].id == "All Platforms") 
              continue
          else if (!platforms[index].id.toLowerCase().trim().includes(keyword)) {
              platforms[index].parentElement.parentElement.style.visibility = "hidden"
              platforms[index].parentElement.parentElement.style.padding = "0"
              platforms[index].parentElement.parentElement.style.height = "0"
          } else {
              platforms[index].parentElement.parentElement.style.visibility = ""
              platforms[index].parentElement.parentElement.style.padding = ""
              platforms[index].parentElement.parentElement.style.height = ""
          }
      }
    },
    getAllStatuses() {
        let header = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
        axios.get(process.env.VUE_APP_URL+`/qflag?page_size=100`,header)
            .then(async response=>{
                if(response.data.meta.code == 200){
                    /**
                     *  Added qflag_id 2 & 8
                     * 
                     *  Deliverable: Hide all For Rework/Reworked elements across all GUI
                     *  Deliverable Number: 454
                     */
                    let status = response.data.data.filter(status => ![0,2,5,6,7,8,9,10,97].includes(status.id))

                    if (this.subStatusesStore.canSeeSubStatuses) {
                        const { data } = await this.$http.get(`substatuses?page_size=10000000`, this.$session.get('ver_headers'))
                        const result = data.data

                        const sub_statuses = {}

                        Object.keys(_qflags).forEach(q => {
                            const qflag = this.parent.getQFlag(q)
                            sub_statuses[qflag] = [{ id: null, label: "Default", qflag_id: q }]
                        })

                        result.forEach(sub => {
                            const qflag = this.parent.getQFlag(sub.qflag_id)
                            sub_statuses[qflag].push(sub)
                        });

                        this.subStatusesStore.subStatuses = sub_statuses
                    }

                    /**
                     *  Deliverable #552: Removal of Invalid status from Artemis
                     */
                    status = status.filter(status => status.id != 99)

                    status = status.map(status=>({ label: status.label, val: status.id }))
                    status.unshift({label: "All", val: 0 })

                    this.dem_statuses = status;
                }
            })
    },
    getQaStatuses(){
        let header = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
        axios.get(process.env.VUE_APP_URL+`/qflag?page_size=20`,header)
            .then(async response=>{
                if(response.data.meta.code == 200){
                    let status = response.data.data.filter(status=>{return [1,2,3,8,31,32,33,34,98,99].includes(status.id) }).map(status=>{return status.label})

                    if (this.subStatusesStore.canSeeSubStatuses) {
                        const { data } = await this.$http.get(`substatuses?page_size=10000000`, this.$session.get('ver_headers'))
                        const result = data.data

                        const sub_statuses = {}

                        Object.keys(_qflags).forEach(q => {
                            const qflag = this.parent.getQFlag(q)
                            sub_statuses[qflag] = [{ id: null, label: "Default", qflag_id: q }]
                        })

                        result.forEach(sub => {
                            const qflag = this.parent.getQFlag(sub.qflag_id)
                            sub_statuses[qflag].push(sub)
                        });

                        this.subStatusesStore.subStatuses = sub_statuses
                    }

                    /**
                     * Deliverable: Hide all For Rework/Reworked elements across all GUI
                     * Deliverable Number: 454
                     */
                    var sortingArr = [/*"Recheck", "Reworked", Hide For Rework / Reworked*/ "Researched", "Qualified", "Monitoring", "Rejected", "Accepted", "Filed", "Has Potential", "Invalid"]

                    status = status.sort(function(a, b){  
                        return sortingArr.indexOf(a) - sortingArr.indexOf(b);
                    });

                    this.dem_statuses = status
                }
            })
    },
    initializeStatuses() {
        if (this.$route.params.campaign_id != null && this.$route.name != "search" && this.$route.path.includes("verification")) {

            let headers = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)

            headers.headers.Where = JSON.stringify({id: this.$route.params.campaign_id})

            axios.get(process.env.VUE_APP_URL+`/my/campaigns`, headers)
            .then(async response=>{
                this.dem_statuses = []
                let campaign = response.data.data.length ? response.data.data[0] : {}
                let obj

                if (this.subStatusesStore.canSeeSubStatuses) {
                    const { data } = await this.$http.get(`substatuses/${this.$route.params.campaign_id}/listingCountPerSubstatus?page_size=10000000`, this.$session.get('ver_headers'))
                    this.subStatusesStore.subStatuses = data.data
                }

                if (Object.keys(campaign).length) {
                    if([1,5,6].includes(this.access_level())){
                        const userObjectCondition = !this.shown_data.includes("RD") && (this.access_level() == 5 || JSON.parse(localStorage.getItem("ver")).role == 2)
                        let objUserKey = userObjectCondition ? 'total_items_by_status_and_team' : 'total_items_by_status_and_user'
                        let objUserSubStatusKey = userObjectCondition ? 'total_item_by_substatus_and_team' : 'total_item_by_substatus_and_user'

                        obj = {
                            "Client Listing"   : campaign[objUserKey]['client listing'],
                            "Work in Progress" : campaign[objUserKey]['work in progress'],
                            // "For Purchasing"   : campaign[objUserKey]["for purchasing"],
                            /**
                             *  Deliverable: Hide all For Rework/Reworked elements across all GUI
                             *  Deliverable Number: 454
                             */
                            // "Recheck"          : campaign[objUserKey].recheck,
                            // "Reworked"         : campaign[objUserKey].reworked,
                            "Researched"       : campaign[objUserKey].researched,
                            "Qualified"        : campaign[objUserKey].qualified,
                            "Monitoring"       : campaign[objUserKey].monitoring,
                            "Accepted"         : campaign[objUserKey].accepted,
                            "Filed"            : campaign[objUserKey].filed,

                            /**
                             *  Deliverable #552: Removal of Invalid status from Artemis
                             */
                            // "Invalid"          : campaign[objUserKey].invalid,

                            "Has Potential"    : campaign[objUserKey]["has potential"],
                            "Archived"         : campaign[objUserKey].archived,
                            'Auto Qualified'   : campaign[objUserKey].hacked,
                            'Rejected'         : campaign[objUserKey].rejected,
                            'Submitted'        : campaign[objUserKey].submitted,
                            'Recycled'         : campaign[objUserKey].recycled
                        }

                        // obj = Object.entries(obj).reduce((a, v) => ({ ...a, [v[0]]: { count: v[1], sub_statuses: this.subStatusesStore.subStatuses[objUserSubStatusKey][v[0]]?.filter(s => s.id) ?? [] } }), {})
                        obj = Object.entries(obj).reduce((a, v) => {
                            let sorted = this.subStatusesStore.subStatuses?.[objUserSubStatusKey]?.[v[0] == "Archived" ? "archived" : v[0]] ?? []
                            sorted.sort((a, b) => a.id ?? 0 - b.id ?? 0)
                            return ({ ...a, [v[0]]: { count: v[1], sub_statuses: sorted.map(s => {
                                const count = sorted.reduce((difference, item) => difference - item.count, v[1])
                                return s.id == null ? {...s, count} : {...s}
                            }) } }) 
                        }, {})

                        /* if (this.access_level() == 5) {
                            obj = { 
                                ...obj,
                                "Auto Qualified"   : campaign[objUserKey]['hacked'],
                                "Rejected" : campaign[objUserKey]['rejected'],
                                "Submitted" : campaign[objUserKey]['submitted'],
                            }
                        } */
                    } else if ([2,7].includes(this.access_level())) {
                        obj = {
                            "Client Listing"   : campaign.total_items_by_status['client listing'],
                            "Work in Progress" : campaign.total_items_by_status["work in progress"],
                            /**
                             *  Deliverable: Hide all For Rework/Reworked elements across all GUI
                             *  Deliverable Number: 454
                             */
                            // "Recheck"          : campaign.total_items_by_status.recheck,
                            // "Reworked"         : campaign.total_items_by_status.reworked,
                            "Researched"       : campaign.total_items_by_status.researched,
                            "Qualified"        : campaign.total_items_by_status.qualified,
                            "Hacked"           : campaign.total_items_by_status.hacked,
                            "Monitoring"       : campaign.total_items_by_status.monitoring,
                            "Rejected"         : campaign.total_items_by_status.rejected,
                            "Accepted"         : campaign.total_items_by_status.accepted,
                            "Submitted"        : campaign.total_items_by_status.submitted,
                            "Filed"            : campaign.total_items_by_status.filed,
                            "Has Potential"    : campaign.total_items_by_status["has potential"],
                            'Recycled'         : campaign.total_items_by_status.recycled,

                            /**
                             *  Deliverable #552: Removal of Invalid status from Artemis
                             */
                            // "Invalid"          : campaign.total_items_by_status.invalid,

                            "Archived"         : campaign.total_items_by_status.archived,
                        }

                        // obj = Object.entries(obj).reduce((a, v) => ({ ...a, [v[0]]: { count: v[1], sub_statuses: this.subStatusesStore.subStatuses.total_item_by_substatus[v[0]]?.filter(s => s.id) ?? [] } }), {})
                        obj = Object.entries(obj).reduce((a, v) => {
                            let sorted = this.subStatusesStore.subStatuses?.total_item_by_substatus?.[v[0] == "Archived" ? "archived" : v[0]] ?? []
                            sorted.sort((a, b) => a.id ?? 0 - b.id ?? 0)
                            return ({ ...a, [v[0]]: { count: v[1], sub_statuses: sorted.map(s => {
                                const count = sorted.reduce((difference, item) => difference - item.count, v[1])
                                return s.id == null ? {...s, count} : {...s}
                            }) } }) 
                        }, {})

                        // console.log(obj)
                        /* if(this.access_level() == 2){
                            delete obj["Work in Progress"]
                            delete obj["Hacked"]
                        } */
                    }

                    this.dem_statuses = Object.entries(obj).map(([key, val]) => { return {label: key, val: val}})
                    this.client_listings_wip = campaign.total_items_by_status_and_user['client listing work in progress']
                    this.client_listings_qa = campaign.total_items_by_status['client listing']
                }
            }).catch(err=>{console.log(err)})
        } else if (this.$route.name == "search") {
            this.getAllStatuses()
        }
    },
    urldecode(url) {
        return decodeURIComponent((url || "").replace(/\+/g, ' '));
    },
    randomColorNumber() {
        return Math.floor((Math.random() * (9 - 1)) + 1)
    },
    getHeight() {
        // return this.$route.path.includes('/verification/search') ? 'height: calc(100vh - 85px)' : 'height: calc(100vh - 82px)' /*'height: calc(100vh - 141px)' original height not on search page is 141px */
        return this.showWeeklyQuotaTracker ? 'height: calc(100vh - 174px)' : 'height: calc(100vh - 82px)'
    },
    getFeatureName(status) {
        const label = status.label.toLowerCase().replace(/\s/g, '-');

        return `v-filter-${label === 'hacked' ? 'auto-qualified' : label}`;
    }
},
  watch: {
    shown_data(val) {
        this.updateShown(val)
        this.initializeStatuses()
    },
    filter_platform() {
        this.filterPlatforms()
    },
    '$route.name': {
        handler() {
            this.initializeStatuses()

            if (((JSON.parse(localStorage.getItem('ver')) || {}).token || null) != null){
                this.getPlatforms()
            }
        },
        deep:true
    },
    '$route.params.campaign_id'(val, oldVal) {
        this.uncheckPlatforms()
        this.initializeStatuses()

        if (((JSON.parse(localStorage.getItem('ver')) || {}).token || null) != null){
            this.getPlatforms()
        }

        if (val != oldVal) this.dem_statuses = []
    },
    '$route.query.status'() {
        if(this.$route.query?.status?.toLowerCase() == 'recheck' || this.$route.query?.status?.toLowerCase() == 'reworked') {
            /*if([6].includes(this.access_level())){
                this.$router.push({name: this.listing_page, query: {status: 'Work in Progress', platform: this.$route.query.platform}})
                this.selected_dem_status = 1
            } else {
                this.$router.push({name: this.listing_page, query: {status: 'Client Listing', platform: this.$route.query.platform}})
                this.selected_dem_status = 0
            }*/

            const isClient = [6].includes(this.access_level())
        
            this.$router.push({name: this.listing_page, query: {status: isClient ? 'Work in Progress' : 'Client Listing', platform: 0, sub_status: 0}})
            this.selected_dem_status = +isClient
        }
    },
    'hasTriggeredSomething'(val) {
        if(val) {
            // Added trigger for checking if there are Clients from Listings 
            
            let gui = 'ver'
            let payload = {
                type: this.access_level == 5 ? 'byCampaignLead' : '',
                gui: gui,
                user_id: this.user_id,
            }
            this.$store.dispatch('setListing_has_clientListings', payload)
            console.log('triggered in ver')
        }
    },
  }
}
</script>

<style scoped>
/*
*   SIDEBAR
*/
.a-sidebar-filter {
    -webkit-transition: top .5s ease, margin .5s ease;
    transition: top .5s ease, margin .5s ease;
    box-shadow: none;
    width: 323px;
    top: 57px;
    right: 0;
}

/* .a-sidebar.a-sidebar-filter {
    padding-bottom: 60px;
} */

.a-sidebar-filter-header {
    /* text-transform: uppercase; */
    font-weight: 700;
    color: #383A3D;
    font-size: 12px;
}

.a-sidebar-filter-header-icon {
    display: inline-block;
    height: 18px;
    width: 14px;
    position: relative;
    top: -2px;
}

.a-nav-filter-item {
    padding-bottom: 11px;
    padding-left: 39px;
    padding-top: 11px;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
}

.custom-control label.custom-control-label {
    color: #515365;
    font-weight: 400;
    font-size: 14px;
}

.btn-group-vertical i.fa-caret-down {
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);  
    z-index: 2; 
}

.btn-research-status.sub-status {
    position: relative;
}

.btn-research-status.sub-status::before {
    content:"";
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #383A3D;
}
/*
*   END OF SIDEBAR
*/
</style>
